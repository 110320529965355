// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import classNames from 'classnames'
import { Container } from './../../basic/Grid'
import ScrollAnimation from 'react-animate-on-scroll'
import Device from '@fcse/tsbob/dist/components/DeviceScreenWidth/Device'
import messages from './ModernizationSteps.lang'
import { examplesList } from './ModernizationSteps'
import baseStyles from './ModernizationSteps.scss'
import arrow from '../../../images/solutions/system-modernization/arrow.svg'
import mobileArrow from '../../../images/solutions/system-modernization/mobile-arrow.svg'

type Props = {
  ...StyleProps,
  examples?: Object
}

const ModernizationSteps = ({ styles, examples = ['example1', 'example2', 'example3'] }: Props) => {
  return (
    <Container className={styles.root} fluid>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <h2 id="examples" className={styles.title}>
          <FormattedMessageSanitized tagName="span" {...messages.title} />
        </h2>
      </ScrollAnimation>
      <div className={styles.examples}>
        {examples.map((item, i) => (
          <section key={'example' + i} className={styles.example}>
            <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
              <h3 className={styles.exampleTitle}>
                <FormattedMessageSanitized tagName="span" {...examplesList[item].title} />
              </h3>
            </ScrollAnimation>
            <div className={styles.row}>
              <div className={styles.descriptionAndImagesWrapper}>
                <div
                  className={classNames(
                    styles.descriptionWrapper,
                    styles['has-' + examplesList[item].descriptions.length]
                  )}
                >
                  {examplesList[item].descriptions.map((description, i) => (
                    <ScrollAnimation
                      className={styles.description}
                      key={i}
                      animateIn="fadeInLeft"
                      animatePreScroll={false}
                      animateOnce
                      delay={500 * i}
                    >
                      <FormattedMessageSanitized tagName="span" {...description} />
                    </ScrollAnimation>
                  ))}
                </div>
                <div className={styles.imagesContainer}>
                  {examplesList[item].images.map((image, i) => (
                    <ScrollAnimation
                      className={styles.imageWrapper}
                      key={i}
                      animateIn="fadeInLeft"
                      animatePreScroll={false}
                      animateOnce
                      delay={500 * i}
                    >
                      <img className={styles.image} src={image} />
                      <img className={styles.arrow} src={arrow} />
                      <img className={styles.mobileArrow} src={mobileArrow} />
                    </ScrollAnimation>
                  ))}
                  {examplesList[item].mobileImages.map((image, i) => (
                    <ScrollAnimation
                      className={styles.imageWrapperMobile}
                      key={i}
                      animateIn="fadeInLeft"
                      animatePreScroll={false}
                      animateOnce
                      delay={500 * i}
                    >
                      <img className={styles.image} src={image} />
                      <img className={styles.mobileArrow} src={mobileArrow} />
                    </ScrollAnimation>
                  ))}
                </div>
              </div>
              <Device desktop>
                {matches => (
                  <ScrollAnimation
                    className={styles.benefitsCol}
                    key={i}
                    animateIn="fadeInLeft"
                    animatePreScroll={false}
                    animateOnce
                    delay={matches ? 1500 : 500 * i}
                  >
                    {examplesList[item].benefits.map((benefits, i) => (
                      <div className={styles.benefit} key={i}>
                        <FormattedMessageSanitized tagName="span" {...benefits} />
                      </div>
                    ))}
                  </ScrollAnimation>
                )}
              </Device>
            </div>
          </section>
        ))}
      </div>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ModernizationSteps)
