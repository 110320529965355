// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SEO from '../../../components/seo'
import SystemModernizationPage from '../../../components/SystemModernizationPage'

import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleSystemModernizationPage)}
        description={formatMessage(metaDescription.SystemModernizationPageDescription)}
        lang={locale}
      />
      <SystemModernizationPage />
    </Layout>
  )
}
export default IndexPage
