// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { useMemo } from 'react'
import { Link as ScrollLink } from 'react-scroll'
import Button from '../../../components/basic/Button'
import { Container } from '../../../components/basic/Grid'
import messages from '../CaseStudy.lang'
import baseStyles from './CaseHeroTeaser.scss'

type Props = {
  ...StyleProps,
  ancor?: boolean,
  title: string,
  titleCaption?: string,
  name?: string,
  backLink?: string,
  backlinkLabel?: boolean,
  description: Object,
  titleLink?: string,
  titleLinkLabel?: string,
  titleLinkIcon?: string,
  appstore?: boolean,
  appstoreImg?: string,
  googleplayImg?: string,
  caseImg?: Object,
  imageDesktop: Object
}

const CaseHeroTeaser = ({
  ancor,
  styles,
  backLink,
  backlinkLabel,
  title,
  titleCaption,
  name,
  description,
  titleLink,
  titleLinkLabel,
  titleLinkIcon = 'icon-arrow_03-right-medium',
  appstore,
  appstoreImg,
  googleplayImg,
  caseImg,
  imageDesktop
}: Props) => {
  const { formatMessage } = useIntl()

  const buttonComponent = useMemo(
    () => (
      <Button styles={styles.btn} tag="span" color="accent" iconRight={titleLinkIcon}>
        {titleLinkLabel ? titleLinkLabel : formatMessage(messages.btnCheck)}
      </Button>
    ),
    [styles, titleLinkLabel, titleLinkIcon]
  )

  return (
    <div className={styles.root}>
      <div className={styles.imageWrap}>
        <div className={styles.imageContainer}>{imageDesktop}</div>
      </div>
      <div className={styles.headlines}>
        <Container className={styles.container} fluid>
          {name && (
            <div className={styles.backLinkWrapper}>
              <Link to={backLink ? backLink : '/case-study/'}>
                <Button styles={styles.backLink} tag="span" color="link" icon="icon-arrow_03-left-medium">
                  {backlinkLabel ? name : `${formatMessage(messages.btnBack)} ${name}`}
                </Button>
              </Link>
            </div>
          )}
          <div className={styles.titleWrap}>
            {titleCaption && <h2 className={styles.titleCaption} dangerouslySetInnerHTML={{ __html: titleCaption }} />}
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: description }} />
            {appstore ? (
              <div className={styles.appstoreWrapper}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://apps.apple.com/de/app/weekend-com-flug-hotel/id1218906575"
                  className={styles.appstoreImg}
                >
                  {appstoreImg}
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={formatMessage(messages.googleplayLink)}
                  className={styles.googleplayImg}
                >
                  {googleplayImg}
                </a>
              </div>
            ) : (
              ''
            )}
            {titleLink &&
              (ancor ? (
                <ScrollLink to={titleLink} smooth={true} offset={-70}>
                  {buttonComponent}
                </ScrollLink>
              ) : (
                <a href={titleLink} target="_blank" rel="noopener noreferrer" className={styles.btnLink}>
                  {buttonComponent}
                </a>
              ))}
          </div>
          {caseImg && (
            <div className={styles.caseImageWrap}>
              <div className={styles.caseImageContainer}>{caseImg}</div>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseHeroTeaser)
