// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Button from '../../components/basic/Button'
import ContactsBlock from '../../components/basic/ContactsBlock'
import IntroTeaser from '../IntroTeaser'

import { StaticImage } from 'gatsby-plugin-image'
import messages from './ContactTeaser.lang'
import baseStyles from './ContactTeaser.scss'

const IntroImage = (
  <StaticImage src="../../images/pictures/contact_intro_image.jpg" objectFit="cover" alt="" className="image" />
)

type Props = {
  ...StyleProps,
  title?: Object,
  subtitle?: Object,
  btnLabel?: Object,
  introInfo?: Object
}

const ContactTeaser = ({ styles, title, subtitle, btnLabel, introInfo }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <IntroTeaser
        titleElement={
          <div className={styles.titleRow}>
            <h2 className={styles.titlePrimary}>{title ? title : formatMessage(messages.titlePrimary)}</h2>
            <h2 className={styles.titleSecondary}>{subtitle ? subtitle : formatMessage(messages.titleSecondary)}</h2>
          </div>
        }
        copy={
          <>
            <p>{introInfo ? introInfo : formatMessage(messages.introInfo)}</p>
            <ContactsBlock />
          </>
        }
        image={IntroImage}
        styles={styles.intro}
      />
      <div className={styles.actionWrapper}>
        <Link to="/contact/" className={styles.btnLink}>
          <Button styles={styles.btn} tag="span" color="accent" iconRight="icon-arrow_03-right-medium" block>
            {btnLabel ? btnLabel : formatMessage(messages.btnTitle)}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ContactTeaser)
