import messages from './ModernizationSteps.lang'

/* eslint-disable max-len */
// Example 1
import imageStep11 from '../../../images/solutions/system-modernization/modernization-step-1-1.svg'
import imageStep12 from '../../../images/solutions/system-modernization/modernization-step-1-2.svg'
import imageStep13 from '../../../images/solutions/system-modernization/modernization-step-1-3.svg'
import imageMobileStep11 from '../../../images/solutions/system-modernization/mobile-modernization-step-1-1.svg'
import imageMobileStep12 from '../../../images/solutions/system-modernization/mobile-modernization-step-1-2.svg'
import imageMobileStep13 from '../../../images/solutions/system-modernization/mobile-modernization-step-1-3.svg'

// Example 2
import imageStep21 from '../../../images/solutions/system-modernization/modernization-step-2-1.svg'
import imageStep22 from '../../../images/solutions/system-modernization/modernization-step-2-2.svg'
import imageStep23 from '../../../images/solutions/system-modernization/modernization-step-2-3.svg'
import imageMobileStep21 from '../../../images/solutions/system-modernization/mobile-modernization-step-2-1.svg'
import imageMobileStep22 from '../../../images/solutions/system-modernization/mobile-modernization-step-2-2.svg'
import imageMobileStep23 from '../../../images/solutions/system-modernization/mobile-modernization-step-2-3.svg'

// Example 3
import imageStep31 from '../../../images/solutions/system-modernization/modernization-step-3-1.svg'
import imageStep32 from '../../../images/solutions/system-modernization/modernization-step-3-2.svg'
import imageStep33 from '../../../images/solutions/system-modernization/modernization-step-3-3.svg'
import imageMobileStep31 from '../../../images/solutions/system-modernization/mobile-modernization-step-3-1.svg'
import imageMobileStep32 from '../../../images/solutions/system-modernization/mobile-modernization-step-3-2.svg'
import imageMobileStep33 from '../../../images/solutions/system-modernization/mobile-modernization-step-3-3.svg'

/* eslint-enable max-len */

export const examplesList = {
  example1: {
    title: messages.example1title,
    descriptions: [
      messages.example1step1,
      messages.example1step2
    ],
    images: [
      imageStep11,
      imageStep12,
      imageStep13
    ],
    mobileImages: [
      imageMobileStep11,
      imageMobileStep12,
      imageMobileStep13
    ],
    benefits: [
      messages.example1benefit1,
      messages.example1benefit2,
      messages.example1benefit3
    ]
  },
  example2: {
    title: messages.example2title,
    descriptions: [
      messages.example2step1,
      messages.example2step2
    ],
    images: [
      imageStep21,
      imageStep22,
      imageStep23
    ],
    mobileImages: [
      imageMobileStep21,
      imageMobileStep22,
      imageMobileStep23
    ],
    benefits: [
      messages.example2benefit1,
      messages.example2benefit2,
      messages.example2benefit3
    ]
  },
  example3: {
    title: messages.example3title,
    descriptions: [
      messages.example3step1,
      messages.example3step2,
      messages.example3step3
    ],
    images: [
      imageStep31,
      imageStep32,
      imageStep33
    ],
    mobileImages: [
      imageMobileStep31,
      imageMobileStep32,
      imageMobileStep33
    ],
    benefits: [
      messages.example3benefit1,
      messages.example3benefit2,
      messages.example3benefit3
    ]
  }
}
