/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  titlePrimary: 'Any questions',
  titleSecondary: 'We’d love to answer them',
  introInfo: 'Do you have any questions? About one of our client projects? Or a vacancy? Or do you want an e.commerce-solution? Do not hesitate contacting us. We look forward to your questions.',
  btnTitle: 'Contact us'
})
