// @flow

import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Clients from '../Clients'
import Scrollindicator from '../../components/basic/Scrollindicator'
import messages from './SystemModernizationPage.lang'
import baseStyles from './SystemModernizationPage.scss'
import CaseHeroTeaser from './../CaseStudy/CaseHeroTeaser'
import ContactTeaser from './../ContactTeaser'
import Solutions from './../Solutions'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import ModernizationSteps from './ModernizationSteps'
import { Container } from './../basic/Grid'
import { StaticImage } from 'gatsby-plugin-image'

const heroBgDesktop = (
  <StaticImage src="../../images/products/mightyc/mightyc-bg-desktop.jpg" alt="" objectFit="cover" className="image" />
)

const heroImgDesktop = (
  <StaticImage src="../../images/solutions/system-modernization.png" alt="" objectFit="contain" className="image" />
)

type Props = {
  ...StyleProps
}

const SystemModernizationPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <CaseHeroTeaser
        titleCaption={formatMessage(messages.titleCaption)}
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        titleLink="examples"
        titleLinkLabel={formatMessage(messages.viewExamples)}
        titleLinkIcon="icon-arrow_03-down-medium"
        ancor
        backLink="/solutions"
        backlinkLabel
        name={formatMessage(messages.backlinkLabel)}
        imageDesktop={heroBgDesktop}
        caseImg={heroImgDesktop}
        styles={styles.heroTeaser}
      />
      <ModernizationSteps />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Clients styles={styles.Clients} defaultTitle />
      </ScrollAnimation>
      <Container>
        <ContactTeaser
          styles={styles.ContactTeaser}
          title={<FormattedMessageSanitized tagName="span" {...messages.contactTeaserTitle} />}
          introInfo={<FormattedMessageSanitized tagName="span" {...messages.contactTeaserIntroInfo} />}
          btnLabel={formatMessage(messages.contactbtnLabel)}
        />
      </Container>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Solutions styles={styles.solutions} />
      </ScrollAnimation>
      <Scrollindicator />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers
          list={['dertouristik', 'plattenplaner', 'lhh', 'etravel', 'aldiana', 'weekend', 'bedfinder']}
          allButton
        />
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(
  SystemModernizationPage
)
