/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: '<b>3 examples</b> how our Vision-2-Reality method can help you',
  example1title: 'Connection layer <b>for iterative modernization</b>',
  example1step1: 'Introduce a connection layer to your old system to gain flexibility',
  example1step2: 'Iteratively replace parts of your old system',
  example1benefit1: '<b>Get independency</b> from your existing system and overcome restrictions by indroducing a connection layer.',
  example1benefit2: '<b>Create new features easily</b> by extending the flexible connection layer with (micro-)services.',
  example1benefit3: '<b>Get a modern UI & UX</b> as design and user flow can be rebuilt independently, across different systems and data on top of the connection layer.',

  example2title: 'Splitting up system components <b>for flexibility</b>',
  example2step1: 'Split your System into flexible Components',
  example2step2: 'Easily exchange / modernize System Components',
  example2benefit1: '<b>Reduce dependencies</b> because if split down into small, flexible components a large system is much more easy to maintain.',
  example2benefit2: '<b>Replace & modernize easily</b> by just focusing on a single component instead of a whole system.',
  example2benefit3: '<b>Extend & grow fast</b> as component based architecture scales very well, fast and cost efficient because dependencies are reduced.',

  example3title: 'Centralize & reuse code <b>for innovation speed up & costs reduction</b>',
  example3step1: 'You may have e.g. multiple Website or Applications to maintain individually',
  example3step2: 'Create reusable functions, patterns and a plattform foundation to reduce complexity and maintenance',
  example3step3: 'Fix issues and introduce new features once across all your system',
  example3benefit1: '<b>Less maintenance</b> as you can manage and develop a plattform instead of individual websites or applicaitons.',
  example3benefit2: '<b>Reduced costs</b> because you can reuse code and features. Adapt or extend the base or core as you need.',
  example3benefit3: '<b>More stability</b> from cross-usecase tested code & features.'
})
